import { createApp } from 'vue';
import App from './App.vue';
import store from './store'; // 引入 Vuex store
import router from './router'; // 引入 Vue Router
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // 引入 Element Plus 的样式
import '@/assets/style/index.scss'

const app = createApp(App);

app.use(store); // 使用 Vuex store
app.use(router); // 使用 Vue Router
app.use(ElementPlus); // 使用 Element Plus

app.mount('#app');