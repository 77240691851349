<template>
  <div class="home-box">
    <div class="header">
      <div class="container">
        <div class="headerInner">
          <div class="row jcb ai headToprow">
            <div class="col-lg-3 rel ai jcb">
              <a href="./">
                <img
                  src="http://img1.xmxmy.com/webImages/logo2.png"
                  class="logo-main"
                />
              </a>
              <i class="fa fa-bars barsBtn"></i>
            </div>
            <div class="col-lg-4">
              <el-form @submit.prevent="onSubmit">
                <div class="headSearchFlex flex">
                  <el-input
                    v-model="searchQuery"
                    name="search"
                    class="searchTop"
                    placeholder=""
                    :suffix-icon="Search"
                  />
                </div>
              </el-form>
            </div>
            <!-- <div class="col-lg-5 hide992">
              <div class="topLinksflex flex jce ai">
                <div class="theme_buttom">
                  <a rel="nofollow">
                    <el-icon><Sunny /></el-icon>
                  </a>
                  &nbsp;
                  <a rel="nofollow">
                    <el-icon><Moon /></el-icon>
                  </a>
                </div>
                <a class="headLink">New</a>
                <a class="headLink">Trending</a>
                <a class="headLink">Top Picks</a>

                <a class="headLogBtn logBtn"
                  ><i class="fa fa-lock"></i> Login</a
                >
                <a class="headLogBtn regBtn"
                  ><i class="fa fa-user"></i> Register</a
                >
              </div>
            </div> -->
          </div>
          <div class="headerNav hide992">
            <ul class="mainUl">
              <li v-for="item in categories" :key="item.name">
                <a class="main-link" href="./">
                  <!-- <img :src="item.src" :alt="item.alt" /> -->
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- xmxmy -->
    <amp-ad
      width="100vw"
      height="320"
      type="adsense"
      data-ad-client="ca-pub-6741681473423260"
      data-ad-slot="2805789712"
      data-auto-format="rspv"
      data-full-width=""
    >
      <div overflow=""></div>
    </amp-ad>
    <!-- art -->
    <!-- <amp-ad
      layout="fixed"
      width="728"
      height="90"
      type="adsense"
      data-ad-client="ca-pub-3835341868730147"
      data-ad-slot="6649132469"
    >
    </amp-ad>
    <amp-auto-ads type="adsense" data-ad-client="ca-pub-3835341868730147">
    </amp-auto-ads> -->
    <div id="ad-container3" align="center"></div>
    <div class="playPage">
      <div class="container">
        <div class="row">
          <!-- Left Side -->
          <div class="col-lg-2 game-col-1">
            <div class="midGmaePanel">
              <p></p>
              <div style="font-size: 11px"><b>ADVERTISEMENT</b></div>
              <p></p>
            </div>
            <div class="game-block-outer">
              <div
                v-for="item in leftList"
                :key="item.name"
                :href="item.aHerf"
                :title="item.gameName"
                class="gameBox-single"
                @click="navigateToGame(item.aHerf)"
              >
                <img
                  :src="item.gameImageUrl"
                  :title="item.gameName"
                  :alt="item.gameName"
                  class="game-single-thumb"
                />
                <div class="game-hov-back">
                  <p class="game-name">{{ item.gameName }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Left Side -->
          <!-- Main -->
          <div class="col-lg-8 game-col-2-">
            <div class="midGmaePanel">
              <div class="gameframeouter" id="gameContainer">
                <iframe
                  id="gameFrame"
                  class="game-frame"
                  :src="frameUrl"
                  title="War Checkers"
                  frameborder="0"
                  allowfullscreen=""
                ></iframe>
              </div>
              <div class="game-block-outer">
                <a
                  v-for="item in centerList"
                  :key="item.name"
                  :href="item.aHerf"
                  :title="item.gameName"
                  class="gameBox-single"
                  @click="navigateToGame(item.aHerf)"
                >
                  <img
                    :src="item.gameImageUrl"
                    :title="item.gameName"
                    :alt="item.gameName"
                    class="game-single-thumb"
                  />
                  <div class="game-hov-back">
                    <p class="game-name">{{ item.gameName }}</p>
                  </div>
                </a>
              </div>
            </div>
            <br />
          </div>
          <!-- Main -->
          <!-- Right Side -->
          <div class="col-lg-2 game-col-3">
            <div class="midGmaePanel">
              <p></p>
              <div style="font-size: 11px"><b>ADVERTISEMENT</b></div>
              <p></p>
            </div>
            <div class="game-block-outer">
              <a
                v-for="item in rightList"
                :key="item.name"
                :href="item.aHerf"
                :title="item.gameName"
                class="gameBox-single"
                @click="navigateToGame(item.aHerf)"
              >
                <img
                  :src="item.gameImageUrl"
                  :title="item.gameName"
                  :alt="item.gameName"
                  class="game-single-thumb"
                />
                <div class="game-hov-back">
                  <p class="game-name">{{ item.gameName }}</p>
                </div>
              </a>
            </div>
          </div>
          <!-- Right Side -->
        </div>
      </div>
    </div>
    <!-- xmxmy -->
    <amp-ad
      width="100vw"
      height="320"
      type="adsense"
      data-ad-client="ca-pub-6741681473423260"
      data-ad-slot="2805789712"
      data-auto-format="rspv"
      data-full-width=""
    >
      <div overflow=""></div>
    </amp-ad>
    <!-- art -->
    <!-- <amp-ad
      layout="fixed"
      width="728"
      height="90"
      type="adsense"
      data-ad-client="ca-pub-3835341868730147"
      data-ad-slot="6649132469"
    >
    </amp-ad>
    <amp-auto-ads type="adsense" data-ad-client="ca-pub-3835341868730147">
    </amp-auto-ads> -->
    <div id="ad-container4" align="center"></div>
    <div class="footer">
      <div class="container">
        <div class="inner">
          <div class="row jcb">
            <div class="col-lg-6">
              <p>All rights reserved by artmutualfunds.com © 2024.</p>
            </div>
            <!-- col -->
            <div class="col-lg-6" align="right">
              <div class="flex footlinksflex jce">
                <a href="https://artmutualfunds.com/blog">Blog</a>
                <a href="https://artmutualfunds.com/about">About</a>
                <a href="https://artmutualfunds.com/contact">Contact Us</a>
                <a href="https://artmutualfunds.com/page/terms-and-conditions"
                  >Terms and Conditions</a
                >
                <a href="https://artmutualfunds.com/page/privacy-policy"
                  >Privacy Policy</a
                >
              </div>
            </div>
            <!-- col -->
          </div>
          <!-- row -->
        </div>
        <!-- inner -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, created, onMounted } from "vue";
import { Search, Sunny, Moon } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";

const searchQuery = ref("");
const activeIndex = ref("Arcade");
const frameUrl = ref("");
const gameId = ref("");
const router = useRouter();
const loadAds = () => {
  // 创建并配置 <script> 元素
  const script = document.createElement("script");
  script.async = true;
  script.src =
    "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6741681473423260";
  script.crossOrigin = "anonymous";

  // 创建并配置 <ins> 元素
  const ins = document.createElement("ins");
  ins.className = "adsbygoogle";
  ins.style.display = "inline-block";
  ins.style.width = "728px";
  ins.style.height = "90px";
  // xmxmy
  // ins.setAttribute("data-ad-client", "ca-pub-5450991634355122");
  // art
  // ins.setAttribute("data-ad-client", "ca-pub-3835341868730147");
  // ins.setAttribute("data-ad-slot", "1234567890");
  // luck37
  ins.setAttribute("data-ad-client", "ca-pub-6741681473423260");
  ins.setAttribute("data-ad-slot", "6265220201");

  // 获取容器元素
  const adContainer = document.getElementById("ad-container3");
  if (adContainer) {
    adContainer.appendChild(ins);
    adContainer.appendChild(script);

    // 初始化广告
    setTimeout(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, 1000); // 根据需要调整延迟时间
  } else {
    console.error("Ad container not found!");
  }
};
const loadAds2 = () => {
  // 创建并配置 <script> 元素
  const script = document.createElement("script");
  script.async = true;
  script.src =
    "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6741681473423260";
  script.crossOrigin = "anonymous";

  // 创建并配置 <ins> 元素
  const ins = document.createElement("ins");
  ins.className = "adsbygoogle";
  ins.style.display = "inline-block";
  ins.style.width = "728px";
  ins.style.height = "90px";
  // xmxmy
  // ins.setAttribute("data-ad-client", "ca-pub-5450991634355122");
  // art
  // ins.setAttribute("data-ad-client", "ca-pub-3835341868730147");
  // ins.setAttribute("data-ad-slot", "1234567890");
  // luck37
  ins.setAttribute("data-ad-client", "ca-pub-6741681473423260");
  ins.setAttribute("data-ad-slot", "6265220201");

  // 获取容器元素
  const adContainer = document.getElementById("ad-container4");
  if (adContainer) {
    adContainer.appendChild(ins);
    adContainer.appendChild(script);

    // 初始化广告
    setTimeout(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, 1000); // 根据需要调整延迟时间
  } else {
    console.error("Ad container not found!");
  }
};

// 在组件挂载后加载广告
onMounted(() => {
  // loadAds();
  // loadAds2();
});

const categories = [
  {
    name: "Arcade",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/arcade.png",
    alt: "Arcade Games by artmutualfunds.com",
  },
  {
    name: "Action",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/action.png",
    alt: "Action Games by artmutualfunds.com",
  },
  {
    name: "Adventure",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/adventure.png",
    alt: "Adventure Games by artmutualfunds.com",
  },
  {
    name: "Shooting",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/shooting.png",
    alt: "Shooting Games by artmutualfunds.com",
  },
  {
    name: "Girl",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/girl.png",
    alt: "Girl Games by artmutualfunds.com",
  },
  {
    name: "Puzzle",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/puzzle.png",
    alt: "Puzzle Games by artmutualfunds.com",
  },
  {
    name: "Sport",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/sport.png",
    alt: "Sport Games by artmutualfunds.com",
  },
  {
    name: "Horror",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/horror.png",
    alt: "Horror Games by artmutualfunds.com",
  },
  {
    name: "Board",
    url: "",
    src: "https://artmutualfunds.com/uploads/categories/board.png",
    alt: "Board Games by artmutualfunds.com",
  },
];
const leftList = [
  {
    gameName: "Paper Doll Fo",
    aHerf: "1",
    gameImageUrl: require("@/assets/image/y1.png"),
    bigFlag: false,
    alt: "Paper Doll For Girls Dress Up",
  },
  {
    gameName: "Color Link Challenge",
    aHerf: "2",
    gameImageUrl: require("@/assets/image/y2.png"),
    bigFlag: false,
    alt: "Color Link Challenge",
  },
  {
    gameName: "Car Jam Color",
    aHerf: "3",
    gameImageUrl: require("@/assets/image/y3.png"),
    bigFlag: true,
    alt: "Car Jam Color",
  },
  {
    gameName: "Happy Glass",
    aHerf: "4",
    gameImageUrl: require("@/assets/image/y4.png"),
    bigFlag: false,
    alt: "Happy Glass",
  },
  {
    gameName: "Slide Blocks",
    aHerf: "5",
    gameImageUrl: require("@/assets/image/y5.png"),
    bigFlag: false,
    alt: "Slide Blocks Puzzle",
  },
  {
    gameName: "Cannon Balls",
    aHerf: "6",
    gameImageUrl: require("@/assets/image/y6.png"),
    bigFlag: false,
    alt: "Cannon Balls - Arcade",
  },
  {
    gameName: "Basketball Sw",
    aHerf: "7",
    gameImageUrl: require("@/assets/image/y7.png"),
    bigFlag: false,
    alt: "Basketball Swooshes",
  },
  {
    gameName: "Build Tower",
    aHerf: "8",
    gameImageUrl: require("@/assets/image/y8.png"),
    bigFlag: false,
    alt: "Build Tower",
  },
  {
    gameName: "Mushroom Pop",
    aHerf: "9",
    gameImageUrl: require("@/assets/image/y9.png"),
    bigFlag: false,
    alt: "Mushroom Pop",
  },
  {
    gameName: "Jumper Bot",
    aHerf: "10",
    gameImageUrl: require("@/assets/image/y10.png"),
    bigFlag: false,
    alt: "Jumper Bot",
  },
  {
    gameName: "Ghost Float",
    aHerf: "11",
    gameImageUrl: require("@/assets/image/y11.png"),
    bigFlag: false,
    alt: "Ghost Float",
  },
  {
    gameName: "Flamingo Bing",
    aHerf: "12",
    gameImageUrl: require("@/assets/image/y12.png"),
    bigFlag: false,
    alt: "Flamingo Bingo",
  },
  {
    gameName: "Zombie Wipeou",
    aHerf: "13",
    gameImageUrl: require("@/assets/image/y13.png"),
    bigFlag: false,
    alt: "Zombie Wipeout",
  },
  {
    gameName: "Dd Pixel Slid",
    aHerf: "14",
    gameImageUrl: require("@/assets/image/y14.png"),
    bigFlag: true,
    alt: "Dd Pixel Slide",
  },
  {
    gameName: "Swipe The Pin",
    aHerf: "15",
    gameImageUrl: require("@/assets/image/y15.png"),
    bigFlag: false,
    alt: "Swipe The Pin",
  },
  {
    gameName: "Oomee Piñata",
    aHerf: "16",
    gameImageUrl: require("@/assets/image/y16.png"),
    bigFlag: false,
    alt: "Oomee Piñata",
  },
  {
    gameName: "Changer Jam",
    aHerf: "17",
    gameImageUrl: require("@/assets/image/y17.png"),
    bigFlag: false,
    alt: "Changer Jam",
  },
  {
    gameName: "Find The Set",
    aHerf: "18",
    gameImageUrl: require("@/assets/image/y18.png"),
    bigFlag: false,
    alt: "Find The Set",
  },
  {
    gameName: "Stickman In J",
    aHerf: "19",
    gameImageUrl: require("@/assets/image/y19.png"),
    bigFlag: false,
    alt: "Stickman In Jail",
  },
  {
    gameName: "Coloring Book",
    aHerf: "20",
    gameImageUrl: require("@/assets/image/y20.png"),
    bigFlag: false,
    alt: "Coloring Book Casino",
  },
];
const rightList = [
  {
    gameName: "2048 Champion",
    aHerf: "22",
    gameImageUrl: require("@/assets/image/y22.png"),
    bigFlag: true,
    alt: "2048 Champion",
  },
  {
    gameName: "Clones",
    aHerf: "23",
    gameImageUrl: require("@/assets/image/y23.png"),
    bigFlag: false,
    alt: "Clones",
  },
  {
    gameName: "Firefighter R",
    aHerf: "24",
    gameImageUrl: require("@/assets/image/y24.png"),
    bigFlag: false,
    alt: "Firefighter Rescue Quest",
  },
  {
    gameName: "Dragon's Defe",
    aHerf: "25",
    gameImageUrl: require("@/assets/image/y25.png"),
    bigFlag: false,
    alt: "Dragon's Defense Reign Of Fire",
  },
  {
    gameName: "Chin Up Shin",
    aHerf: "26",
    gameImageUrl: require("@/assets/image/y26.png"),
    bigFlag: true,
    alt: "Chin Up Shin Up",
  },
  {
    gameName: "Counter Craft",
    aHerf: "27",
    gameImageUrl: require("@/assets/image/y27.png"),
    bigFlag: false,
    alt: "Counter Craft 5",
  },
  {
    gameName: "The Bomb",
    aHerf: "28",
    gameImageUrl: require("@/assets/image/y28.png"),
    bigFlag: true,
    alt: "The Bomb",
  },
  {
    gameName: "Princesses At",
    aHerf: "29",
    gameImageUrl: require("@/assets/image/y29.png"),
    bigFlag: false,
    alt: "Princesses At Horror School",
  },
  {
    gameName: "Number Match",
    aHerf: "30",
    gameImageUrl: require("@/assets/image/y30.png"),
    bigFlag: false,
    alt: "Number Match - Match Ten",
  },
  {
    gameName: "Ultimate Noug",
    aHerf: "31",
    gameImageUrl: require("@/assets/image/y31.png"),
    bigFlag: false,
    alt: "Ultimate Noughts &amp; Crosses",
  },
  {
    gameName: "Arrowblob Puz",
    aHerf: "32",
    gameImageUrl: require("@/assets/image/y32.png"),
    bigFlag: false,
    alt: "Arrowblob Puzzle Quest",
  },
  {
    gameName: "Ascend",
    aHerf: "33",
    gameImageUrl: require("@/assets/image/y33.png"),
    bigFlag: false,
    alt: "Ascend",
  },
  {
    gameName: "Race",
    aHerf: "34",
    gameImageUrl: require("@/assets/image/y34.png"),
    bigFlag: false,
    alt: "Race",
  },
  {
    gameName: "Tanks Battle",
    aHerf: "35",
    gameImageUrl: require("@/assets/image/y35.png"),
    bigFlag: false,
    alt: "Tanks Battle Field",
  },
  {
    gameName: "Battlegrid On",
    aHerf: "36",
    gameImageUrl: require("@/assets/image/y36.png"),
    bigFlag: false,
    alt: "Battlegrid Online Pvp Tank Battles",
  },
  {
    gameName: "Pumpkin Of Go",
    aHerf: "37",
    gameImageUrl: require("@/assets/image/y37.png"),
    bigFlag: false,
    alt: "Pumpkin Of Goo",
  },
  {
    gameName: "Apostle's Cre",
    aHerf: "38",
    gameImageUrl: require("@/assets/image/y38.png"),
    bigFlag: false,
    alt: "Apostle's Creed I",
  },
  {
    gameName: "Zombie Runner",
    aHerf: "39",
    gameImageUrl: require("@/assets/image/y39.png"),
    bigFlag: true,
    alt: "Zombie Runner",
  },
  {
    gameName: "Fruit Name Ju",
    aHerf: "40",
    gameImageUrl: require("@/assets/image/y40.png"),
    bigFlag: false,
    alt: "Fruit Name Jump",
  },
  {
    gameName: "Yummy Donut F",
    aHerf: "41",
    gameImageUrl: require("@/assets/image/y41.png"),
    bigFlag: false,
    alt: "Yummy Donut Factory",
  },
];
const centerList = [
  {
    gameName: "Battlegrid On",
    aHerf: "36",
    gameImageUrl: require("@/assets/image/y36.png"),
    bigFlag: false,
    alt: "Battlegrid Online Pvp Tank Battles",
  },
  {
    gameName: "2048colourbal",
    aHerf: "43",
    gameImageUrl: require("@/assets/image/y43.png"),
    bigFlag: true,
    alt: "2048colourballs",
  },
  {
    gameName: "Simple Math Q",
    aHerf: "44",
    gameImageUrl: require("@/assets/image/y44.png"),
    bigFlag: false,
    alt: "Simple Math Quiz",
  },
  {
    gameName: "Cowboy Clash",
    aHerf: "45",
    gameImageUrl: require("@/assets/image/y45.png"),
    bigFlag: false,
    alt: "Cowboy Clash",
  },
  {
    gameName: "Rail Rush Pro",
    aHerf: "46",
    gameImageUrl: require("@/assets/image/y46.png"),
    bigFlag: false,
    alt: "Rail Rush Pro",
  },
  {
    gameName: "Jewel Aquariu",
    aHerf: "47",
    gameImageUrl: require("@/assets/image/y47.png"),
    bigFlag: false,
    alt: "Jewel Aquarium",
  },
  {
    gameName: "Neon Star Bri",
    aHerf: "48",
    gameImageUrl: require("@/assets/image/y48.png"),
    bigFlag: false,
    alt: "Neon Star Bricks",
  },
  {
    gameName: "Football Jump",
    aHerf: "49",
    gameImageUrl: require("@/assets/image/y49.png"),
    bigFlag: false,
    alt: "Football Jump",
  },
  {
    gameName: "Alternating E",
    aHerf: "50",
    gameImageUrl: require("@/assets/image/y50.png"),
    bigFlag: false,
    alt: "Alternating Escape",
  },
  {
    gameName: "Macro Maze",
    aHerf: "51",
    gameImageUrl: require("@/assets/image/y51.png"),
    bigFlag: false,
    alt: "Macro Maze",
  },
  {
    gameName: "Trick Or Trea",
    aHerf: "52",
    gameImageUrl: require("@/assets/image/y52.png"),
    bigFlag: true,
    alt: "Trick Or Treat Halloween Games",
  },
  {
    gameName: "Princess Hall",
    aHerf: "53",
    gameImageUrl: require("@/assets/image/y53.png"),
    bigFlag: false,
    alt: "Princess Halloween Makeup Halffaces Tutorial",
  },
  {
    gameName: "Tomato Ketchu",
    aHerf: "54",
    gameImageUrl: require("@/assets/image/y54.png"),
    bigFlag: true,
    alt: "Tomato Ketchup",
  },
  {
    gameName: "Crazy Christm",
    aHerf: "55",
    gameImageUrl: require("@/assets/image/y55.png"),
    bigFlag: false,
    alt: "Crazy Christmas Fun",
  },
  {
    gameName: "Jump Up 3d",
    aHerf: "56",
    gameImageUrl: require("@/assets/image/y56.png"),
    bigFlag: false,
    alt: "Jump Up 3d",
  },
  {
    gameName: "Space Battles",
    aHerf: "57",
    gameImageUrl: require("@/assets/image/y57.png"),
    bigFlag: false,
    alt: "Space Battleship Orion",
  },
  {
    gameName: "Words Escapes",
    aHerf: "58",
    gameImageUrl: require("@/assets/image/y58.png"),
    bigFlag: false,
    alt: "Words Escapes - Puzzle",
  },
  {
    gameName: "Tractor Rush",
    aHerf: "59",
    gameImageUrl: require("@/assets/image/y59.png"),
    bigFlag: false,
    alt: "Tractor Rush",
  },
  {
    gameName: "Zombie Runner",
    aHerf: "39",
    gameImageUrl: require("@/assets/image/y39.png"),
    bigFlag: true,
    alt: "Zombie Runner",
  },
  {
    gameName: "Fruit Name Ju",
    aHerf: "40",
    gameImageUrl: require("@/assets/image/y40.png"),
    bigFlag: false,
    alt: "Fruit Name Jump",
  },
  {
    gameName: "Yummy Donut F",
    aHerf: "41",
    gameImageUrl: require("@/assets/image/y41.png"),
    bigFlag: false,
    alt: "Yummy Donut Factory",
  },
];
const navigateToGame = (index) => {
  router.push({ name: "play", query: { gameId: index } });
  getGameIdFromUrl();
};
const getGameIdFromUrl = () => {
  const urlParams = window.location.search;
  // 去掉查询字符串开头的 ?
  const paramsArray = urlParams.slice(1).split("&");

  // 查找 gameId 参数
  const gameIdParam = paramsArray.find((param) => param.startsWith("gameId="));

  if (gameIdParam) {
    const gameIdValue = gameIdParam.split("=")[1];

    if (gameIdValue) {
      gameId.value = gameIdValue;
      frameUrl.value = `https://xmxmy.com/gameTesting/${gameIdValue}/index.html`;
      console.log(frameUrl.value, "frameUrl.value");
    }
  }
};

const onSubmit = () => {
  console.log("Search query:", searchQuery.value);
};
onMounted(() => {
  getGameIdFromUrl();
});
</script>

<style lang="scss">
.home-box {
  background-image: url("../../assets/image/tri.jpg");
}
.headToprow {
  display: flex;
}
@media screen and (orientation: landscape) {
  .row {
    display: flex;
    justify-content: space-around;
    > div {
      margin-left: 40px;
    }
    .game-col-2- {
      flex: 1;
    }
  }
}
@media screen and (orientation: portrait) {
  .home-box {
    padding: 0 20px;
    box-sizing: border-box;
  }
}
</style>
